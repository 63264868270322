<template>
    <WithClickOutsideDetection :handler="isDropdownOpen ? 'close' : null">
        <div class="active-filters">
            <ButtonComponent
                v-show="selectedTagsCount"
                :variant="BUTTON_VARIANT"
                class="toggle-button"
                tabindex="1"
                @click="toggleOpen()"
            >
                {{ selectedFiltersLabel }}
            </ButtonComponent>

            <CatalogDropdownPanel
                v-if="isDropdownOpen"
                :is-open="isDropdownOpen"
                :is-loading="false"
                class="active-filters-dropdown"
                @click-outside="close()"
            >
                <ActiveFiltersContent
                    class="active-filters"
                    @apply-search-config="applySearchConfig()"
                />
                <template #footer>
                    <ButtonText
                        :variant="CLEAR_BUTTON_VARIANT"
                        :wide="true"
                        class="clear-button"
                        @click="clearAllFilters()"
                    >
                        {{ $t('Remove all filters') }}
                    </ButtonText>
                </template>
            </CatalogDropdownPanel>

            <CatalogSideModal
                v-if="isModalOpen"
                :is-open="isModalOpen"
                :slide-from="SLIDE_FROM_BOTTOM"
                class="active-filters-modal"
                @close="close()"
                @click-outside="close()"
            >
                <template #header>
                    <BaseHeading class="header-title">
                        {{ selectedFiltersText }}
                    </BaseHeading>
                    <CircleDigit :digit="selectedTagsCount" />
                </template>
                <ActiveFiltersContent
                    class="active-filters"
                    @apply-search-config="applySearchConfig()"
                />
                <template #footer>
                    <ButtonText
                        :variant="CLEAR_BUTTON_VARIANT"
                        :wide="true"
                        class="clear-button"
                        @click="clearAllFilters()"
                    >
                        {{ $t('Remove all filters') }}
                    </ButtonText>
                </template>
            </CatalogSideModal>
        </div>
    </WithClickOutsideDetection>
</template>

<script>
import { applySearchConfiguration } from '@assets/filters';

import { SLIDE_FROM_BOTTOM } from '@types/SideModal';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';
import { REMOVE_ALL } from '@analytics-modules/catalog/types/Actions';
import { CATEGORY_FILTER_REMOVE } from '@analytics-modules/catalog/types/Events';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import { ShevronDown, ShevronUp } from '@eobuwie-ui/icons/v1/other';

import {
    ButtonText,
    BUTTON_TEXT_VARIANTS,
} from '@eobuwie-ui/components/ButtonText/v1';
import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { CircleDigit } from '@eobuwie-ui/components/CircleDigit/v1';

export default {
    name: 'ActiveFilters',

    components: {
        BaseHeading,
        WithClickOutsideDetection,
        CircleDigit,
        ButtonText,
        ButtonComponent,
        CatalogDropdownPanel: () => ({
            component: import(
                /* webpackChunkName: "dropdown-panel" */
                '@molecules/CatalogDropdownPanel/CatalogDropdownPanel'
            ),
        }),

        CatalogSideModal: () => ({
            component: import(
                /* webpackChunkName: "catalog-side-modal" */
                '@molecules/CatalogSideModal/CatalogSideModal'
            ),
        }),

        ActiveFiltersContent: () => ({
            component: import(
                /* webpackChunkName: "active-filters-content" */
                '@molecules/ActiveFiltersContent/ActiveFiltersContent'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        selectedFiltersText() {
            return this.$t('Selected filters');
        },

        selectedTagsCount() {
            return (
                this.$filterManager.selectedFiltersCount +
                this.$filterManager.selectedExtraFiltersCount
            );
        },

        selectedFiltersLabel() {
            return `${this.$t('Selected filters')} (${this.selectedTagsCount})`;
        },

        isDropdownOpen() {
            return this.isOpen && !this.isMobile;
        },

        isModalOpen() {
            return this.isOpen && this.isMobile;
        },
    },

    beforeCreate() {
        this.SLIDE_FROM_BOTTOM = SLIDE_FROM_BOTTOM;
        this.ShevronDown = ShevronDown;
        this.ShevronUp = ShevronUp;
        this.CLEAR_BUTTON_VARIANT = BUTTON_TEXT_VARIANTS.PRIMARY;
        this.BUTTON_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY_ACCENT;
    },

    methods: {
        close() {
            this.isOpen = false;
        },

        toggleOpen() {
            this.isOpen = !this.isOpen;
        },

        clearAllFilters() {
            this.close();

            this.$filterManager.clearAllFilters();
            this.$filterManager.clearExtraFilters();

            this.$analytics.moduleEmit(
                CATALOG_MODULE_NAME,
                CATEGORY_FILTER_REMOVE,
                {
                    eventAction: REMOVE_ALL,
                    filterCodeAndValue: 'All',
                }
            );

            applySearchConfiguration(this, this.isMobile);
        },

        applySearchConfig() {
            this.close();

            applySearchConfiguration(this, this.isMobile);
        },
    },
};
</script>

<style lang="scss" scoped>
.active-filters {
    @apply relative;

    .toggle-button {
        @apply flex items-center;
    }

    .icon {
        @apply text-primary ml-2;
    }

    .header-title {
        @apply truncate mr-2;
    }

    .active-filters {
        @apply p-3;
    }

    .clear-button {
        @apply w-full mx-3;
    }
}
</style>
